<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="topbar-item text-decoration-none"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <div
        class="topbar-item mr-4"
        data-toggle="dropdown"
        data-offset="10px,0px"
      >
        <div class="btn font-weight-bolder btn-sm btn-light-success px-5">
           {{$t('DropdownCreate.REGISTERNEWDEVICE')}}

        </div>
      </div>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover min-w-md-250px py-5">
      <b-dropdown-text tag="div" class="navi-item">
         <router-link
            to="/setup/new-device"
            v-slot="{ href, navigate }"
          >
          <a :href="href" @click="navigate" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-add-square"></i>
          </span>
          <span class="navi-text">
            {{$t('DropdownCreate.RegisterProduct')}}
          </span>
        </a>
        </router-link>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
         <router-link
            to="/setup/network-device"
            v-slot="{ href, navigate }"
          >
          <a :href="href" @click="navigate" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-shelter"></i>
          </span>
          <span class="navi-text">
            {{$t('DropdownCreate.AddFromNetwork')}}
            </span>
        </a>
        </router-link>
      </b-dropdown-text>  
      <b-dropdown-text tag="div" class="navi-item">
        <router-link
            to="/setup/virtual-device"
            v-slot="{ href, navigate }"
          >
          <a :href="href" @click="navigate" class="navi-link">
            <span class="navi-icon">
              <i class="flaticon2-layers-1"></i>
            </span>
            <span class="navi-text">
              {{$t('DropdownCreate.CreateVirtualDevice')}}
            </span>
          </a>
        </router-link>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<style lang="scss">
.dropdown-menu {
  outline: none;
}
</style>

<script>
export default {
  name: "dropdown-1",
  components: {},
  mounted() {}
};
</script>
