var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topbar-item mr-4"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"id":"kt_quick_actions_toggle","title":"Device options"}},[_c('a',{staticClass:"btn btn-icon btn-sm btn-clean btn-text-dark-75",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon svg-icon-lg"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Layout/Layout-4-blocks.svg"}})],1)])]),_c('div',{ref:"kt_quick_actions",staticClass:"offcanvas offcanvas-right p-10",attrs:{"id":"kt_quick_actions"}},[_vm._m(0),_c('div',{staticClass:"offcanvas-content pr-5 mr-n5"},[_c('div',{staticClass:"row gutter-b"},[_c('div',{staticClass:"col-6"},[_c('router-link',{attrs:{"to":"/device/all"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-primary m-0"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Clipboard-list.svg"}})],1),_c('span',{staticClass:"d-block font-weight-bold font-size-h6 mt-2"},[_vm._v("My devices")])])]}}])})],1),_c('div',{staticClass:"col-6"},[_c('router-link',{attrs:{"to":"/layout-builder/builder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-primary m-0"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Shopping/Box2.svg"}})],1),_c('span',{staticClass:"d-block font-weight-bold font-size-h6 mt-2"},[_vm._v("Layout Maker")])])]}}])})],1)]),_c('div',{staticClass:"row gutter-b"},[_c('div',{staticClass:"col-6"},[_c('router-link',{attrs:{"to":"/chain-reaction/chain-reaction"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-primary m-0"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Color-profile.svg"}})],1),_c('span',{staticClass:"d-block font-weight-bold font-size-h6 mt-2"},[_vm._v("If..then")])])]}}])})],1),_c('div',{staticClass:"col-6"},[_c('router-link',{attrs:{"to":"/chart/advanced"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-3x svg-icon-primary m-0"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Shopping/Chart-bar1.svg"}})],1),_c('span',{staticClass:"d-block font-weight-bold font-size-h6 mt-2"},[_vm._v("Charting")])])]}}])})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"offcanvas-header d-flex align-items-center justify-content-between pb-10"},[_c('h3',{staticClass:"font-weight-bold m-0"},[_vm._v(" Quick Actions "),_c('small',{staticClass:"text-muted font-size-sm ml-2"},[_vm._v("Device options")])]),_c('a',{staticClass:"btn btn-xs btn-icon btn-light btn-hover-primary",attrs:{"href":"#","id":"kt_quick_actions_close"}},[_c('i',{staticClass:"ki ki-close icon-xs text-muted"})])])}]

export { render, staticRenderFns }