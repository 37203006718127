var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-dropdown',{attrs:{"size":"sm","variant":"link","toggle-class":"topbar-item text-decoration-none","no-caret":"","right":"","no-flip":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"topbar-item mr-4",attrs:{"data-toggle":"dropdown","data-offset":"10px,0px"}},[_c('div',{staticClass:"btn font-weight-bolder btn-sm btn-light-success px-5"},[_vm._v(" "+_vm._s(_vm.$t('DropdownCreate.REGISTERNEWDEVICE'))+" ")])])]},proxy:true}])},[_c('div',{staticClass:"navi navi-hover min-w-md-250px py-5"},[_c('b-dropdown-text',{staticClass:"navi-item",attrs:{"tag":"div"}},[_c('router-link',{attrs:{"to":"/setup/new-device"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"navi-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon"},[_c('i',{staticClass:"flaticon2-add-square"})]),_c('span',{staticClass:"navi-text"},[_vm._v(" "+_vm._s(_vm.$t('DropdownCreate.RegisterProduct'))+" ")])])]}}])})],1),_c('b-dropdown-text',{staticClass:"navi-item",attrs:{"tag":"div"}},[_c('router-link',{attrs:{"to":"/setup/network-device"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"navi-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon"},[_c('i',{staticClass:"flaticon2-shelter"})]),_c('span',{staticClass:"navi-text"},[_vm._v(" "+_vm._s(_vm.$t('DropdownCreate.AddFromNetwork'))+" ")])])]}}])})],1),_c('b-dropdown-text',{staticClass:"navi-item",attrs:{"tag":"div"}},[_c('router-link',{attrs:{"to":"/setup/virtual-device"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"navi-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon"},[_c('i',{staticClass:"flaticon2-layers-1"})]),_c('span',{staticClass:"navi-text"},[_vm._v(" "+_vm._s(_vm.$t('DropdownCreate.CreateVirtualDevice'))+" ")])])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }